import React from 'react'
import { Helmet } from "react-helmet"
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import GoogleReviews from '../components/google_reviews_slider'
import Copyright from '../components/copyright'

function getSection (allMarkdownRemarkGroup, section) {
    let result = [];
	for (let edges of allMarkdownRemarkGroup) {
        for (let item of edges.edges) {
            if (item.node.frontmatter.section === section) {
                result.push(item.node)
            }
        }
    }
	return result;
}

const NavSection = (p) => <div className="flex flex__space-around">
    <div className="blog__60">
        {p.navSectionItems.map( (p, i) => (
            <div key={i} className="blog__list">
                <h3><Link to={p.fields.slug}>{p.frontmatter.title}</Link> &rsaquo;</h3>
                <p>{p.excerpt}</p>
            </div>
        ) )}
    </div>
</div>

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Oswald&display=swap" rel="stylesheet" />
            </Helmet>
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <BackgroundImage fluid={entry.frontmatter.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                    <div className="title__gradient">
                        <div className="layout__container">
                            <div className="layout__wide title center">
                                <h1>{entry.frontmatter.heading}</h1>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            <div className="layout grey">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb">
                            <p><Link to='/'>Home</Link> &nbsp;&rsaquo;&nbsp; <strong>{entry.frontmatter.title}</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <NavSection navSectionItems={getSection(data.allMarkdownRemark.group, 'blog')} />
                    </div>
                </div>
            </div>
            <GoogleReviews />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
        allMarkdownRemark {
            group(field: frontmatter___section) {
                edges {
                    node {
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                            section
                        }
                        excerpt(pruneLength: 250)
                    }
                }
            }
        }
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
                section
                title
				page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`